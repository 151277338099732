<template>
  <div
    class="card-menu"
    :class="{ 'card-menu--active': isOpenMenu }"
    v-click-outside="closeMenu"
  >
    <button
      type="button"
      class="card-menu__btn"
      :class="{ 'card-menu__btn--active': isOpenMenu }"
      @click.prevent="toggleMenu"
    >
      <AppIcon componentName="DotsVerticalIcon" />
    </button>

    <transition name="fade">
      <div v-if="isOpenMenu" class="menu-dd">
        <AppIcon componentName="TriangleSimpleIcon" class="menu-dd__arrow" />

        <button
          type="button"
          class="menu-dd-item"
          @click.prevent="emitEvent('edit')"
        >
          <AppIcon componentName="EditIcon" class="menu-dd-item__icon" />
          <p class="menu-dd-item__text">Edit</p>
        </button>

        <button
          type="button"
          class="menu-dd-item"
          @click.prevent="emitEvent('duplicate')"
        >
          <AppIcon componentName="DuplicateIcon" class="menu-dd-item__icon" />
          <p class="menu-dd-item__text">Duplicate</p>
        </button>

        <button
          type="button"
          class="menu-dd-item"
          @click.prevent="emitEvent('print')"
        >
          <AppIcon componentName="PrintIcon" class="menu-dd-item__icon" />
          <p class="menu-dd-item__text">Print</p>
        </button>

        <button
          type="button"
          class="menu-dd-item"
          @click.prevent="emitEvent('sendViaEmail')"
        >
          <AppIcon componentName="MailIcon" class="menu-dd-item__icon" />
          <p class="menu-dd-item__text">Send via e-mail</p>
        </button>

        <DownloadOptions v-on="$listeners" @download="closeMenu" />

        <span class="menu-dd__separator" />

        <button
          type="button"
          class="menu-dd-item menu-dd-item--remove"
          @click.prevent="emitEvent('remove')"
        >
          <AppIcon componentName="TrashIcon" class="menu-dd-item__icon" />
          <p class="menu-dd-item__text">Delete</p>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import DownloadOptions from "@/components/dashboard/DownloadOptions";

export default {
  name: "TemplateCardMenu",
  components: { DownloadOptions },

  data() {
    return {
      isOpenMenu: false,
    };
  },

  methods: {
    emitEvent(eventName) {
      this.closeMenu();
      this.$emit(eventName);
    },

    toggleMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    },

    closeMenu() {
      this.isOpenMenu = false;
    },
  },
};
</script>

<style scoped lang="scss" src="./index.scss" />
