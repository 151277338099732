<template>
  <div class="card">
    <figure class="card__img">
      <img
        :src="`${template.preview_url}?v=${Date.now()}&token=${authToken}`"
        alt=""
      />

      <div v-if="template.is_draft" class="card-musk">
        <button
          type="button"
          class="card-musk__label"
          @click.prevent="$emit('edit')"
        >
          Draft
        </button>
      </div>
      <div v-else class="card-musk card-musk--hover">
        <button
          type="button"
          class="card-musk__label"
          @click.prevent="$emit('redirect', template.hash)"
        >
          View
        </button>
      </div>
    </figure>

    <h3 v-if="template.is_draft" class="card__name">
      {{ fullName }}
    </h3>
    <a
      v-else
      href="#"
      class="card__name"
      @click.prevent="$emit('redirect', template.hash)"
      >{{ fullName }}</a
    >

    <p class="card__date">Last updated: {{ updatedAt }}</p>

    <TemplateCardMenu class="card-options" v-on="$listeners" />
  </div>
</template>

<script>
import TemplateCardMenu from "@/components/dashboard/template-card-menu/TemplateCardMenu";
import AccessToken from "@/helpers/access-token";

export default {
  name: "TemplateCard",
  components: { TemplateCardMenu },

  props: {
    template: {
      type: Object,
    },
  },

  computed: {
    fullName() {
      return `${this.template?.first_name || ""} ${
        this.template?.last_name || ""
      }`;
    },

    updatedAt() {
      const options = {
        // weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return new Date(this.template.updated_at).toLocaleDateString(
        "en-US",
        options
      );
    },

    authToken() {
      return AccessToken.get();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  position: relative;

  &__img {
    width: 100%;
    padding-top: 240/270 * 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 16px;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
    display: inline-flex;
  }

  &__date {
    font-size: 12px;
    color: $light-gray;
  }
}

::v-deep .card-options.card-options {
  position: absolute;
  right: 16px;
  top: 16px;
}

.card-musk {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.8);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #fff;
    padding: 8px 16px;
    background-color: #c2d1f7;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: $primary;
    }
  }

  &--hover {
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
