import AppToast from "@/helpers/toast-methods";

export const showTextMixin = {
  methods: {
    device() {
      if (
        /Mobi/.test(navigator.userAgent) ||
        /Android|webOS|Tablet|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return "device";
      }
      return "PC";
    },
    showTextWillDownload() {
      AppToast.toastSuccess(
        `The file will be downloaded to your ${this.device()} soon...`
      );
    },
    showTextWillPrint() {
      AppToast.toastSuccess("The file will be sent to print soon...");
    },
  },
};
