<template>
  <section class="dashboard-section">
    <div class="container">
      <div class="row">
        <div class="col-xl-7">
          <div class="dashboard-header">
            <h1 class="dashboard-header__title">Hi, {{ userFirstName }}</h1>
            <p class="dashboard-header__text">
              Welcome to your library. This is where you’ll find all the
              templates you used.
            </p>
          </div>
        </div>
      </div>

      <div class="user-templates">
        <h2 class="user-templates__title">My Resumes</h2>

        <NoTemplates
          v-if="!resumes.length"
          title="You Don't Have Any Resume Yet"
        >
          Create your first resume now. We’ll help you build an impressive,
          job-specific resume that will increase your chances of getting hired.
          Choose a template from our library to start.
        </NoTemplates>

        <div v-if="resumes.length" class="templates row">
          <div class="templates__item col-md-6 col-lg-4 col-xl-3">
            <router-link
              :to="ROUT_PATH.BROWS_RESUME_TEMPLATES"
              class="new-template"
            >
              <AppIcon componentName="PlusIcon" />
              Create new resume
            </router-link>
          </div>

          <div
            v-for="resume of resumes"
            :key="resume.hash"
            class="templates__item col-md-6 col-lg-4 col-xl-3"
          >
            <TemplateCard
              :template="resume"
              @redirect="redirectToDetails(true, $event)"
              @download="download($event, resume.hash)"
              @edit="edit(resume.hash)"
              @duplicate="duplicate(resume.hash)"
              @print="print(resume.hash)"
              @sendViaEmail="openModal(resume.hash)"
              @remove="remove(resume.hash)"
            />
          </div>
        </div>
      </div>

      <div class="user-templates">
        <h2 class="user-templates__title">My Cover Letters</h2>

        <NoTemplates
          v-if="!letters.length"
          :isResume="false"
          title="You Don't Have Any Cover Letters Yet"
        >
          Create your first cover letter now. We’ll help you build an
          impressive, job-specific cover letter that will increase your chances
          of getting hired. Choose a template from our library to start.
        </NoTemplates>

        <div v-if="letters.length" class="templates row">
          <div class="templates__item col-md-6 col-lg-4 col-xl-3">
            <router-link
              :to="ROUT_PATH.BROWS_LETTERS_TEMPLATES"
              class="new-template"
            >
              <AppIcon componentName="PlusIcon" />
              Create new cover letter
            </router-link>
          </div>

          <div
            v-for="letter of letters"
            :key="letter.hash"
            class="templates__item col-md-6 col-lg-4 col-xl-3"
          >
            <TemplateCard
              :template="letter"
              @redirect="redirectToDetails(false, $event)"
              @download="download($event, letter.hash, false)"
              @edit="edit(letter.hash, false)"
              @duplicate="duplicate(letter.hash, false)"
              @print="print(letter.hash, false)"
              @sendViaEmail="openModal(letter.hash, false)"
              @remove="remove(letter.hash, false)"
            />
          </div>
        </div>
      </div>
    </div>
    <AppModal width="636px" :isOpenModal="isOpenModal" @close="closeModal">
      <SendViaEmailModal
        :title="!!currentResumeHash ? 'Resume' : 'Cover Letter'"
        @sendEmail="sendViaEmail"
      />
    </AppModal>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import printJS from "print-js";

import {
  ROUT_PATH,
  SUBSCRIBE_STEPS_NAMES,
  TEMPLATE_EDITING_NAMES,
  USER_ACCOUNT_NAMES,
} from "@/helpers/rout-constants";
import AppToast from "@/helpers/toast-methods";
import { downloadingMixin } from "@/mixins/DownloadingMixin";
import { showTextMixin } from "@/mixins/ShowTextMixin";

import NoTemplates from "@/views/dashboard/components/NoTemplatesBlock";
import TemplateCard from "@/views/dashboard/components/TemplateCard";
import SendViaEmailModal from "@/components/dashboard/SendViaEmailModal";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import * as FullStory from "@fullstory/browser";
import CompletedTemplate from "@/helpers/completed-template-hash";

export default {
  name: "UserDashboard",
  mixins: [downloadingMixin, showTextMixin, mixpanelMixin],
  components: { NoTemplates, TemplateCard, SendViaEmailModal },

  data() {
    return {
      ROUT_PATH,
      isOpenModal: false,
      currentResumeHash: null,
      currentLetterHash: null,
    };
  },

  computed: {
    ...mapGetters({
      user: "user/user",
      isSubscribed: "user/isSubscribed",
      resumes: "userDashboard/getResumes",
      letters: "userDashboard/getCoverLetter",
    }),

    userFirstName() {
      return this.user?.first_name || "";
    },

    isResume() {
      return !!this.currentResumeHash;
    },
  },

  methods: {
    redirectToDetails(isResume = true, templateHash) {
      const routeName = isResume
        ? USER_ACCOUNT_NAMES.USER_RESUME_DETAILS
        : USER_ACCOUNT_NAMES.USER_LETTER_DETAILS;

      this.$router.push({ name: routeName, params: { id: templateHash } });
    },

    redirectUnsubscribed(hash) {
      if (!this.isSubscribed) {
        if (this.isResume) {
          CompletedTemplate.set({ hash: hash, type: "resume" });
        } else {
          CompletedTemplate.set({ hash: hash, type: "letter" });
        }

        this.$router.push({ name: SUBSCRIBE_STEPS_NAMES.SUBSCRIBE_PLANS });
        return false;
      }

      return true;
    },

    async download(format, hash, isResume = true) {
      if (!this.redirectUnsubscribed(hash)) return;

      this.showTextWillDownload();

      const fileBlob = await this.getFile(hash, format, isResume);

      const fileName = await this.getFileName(hash, format, isResume);

      this.initialisationDownloadFile(fileBlob, fileName);
    },

    async print(hash, isResume = true) {
      console.log(hash);
      if (!this.redirectUnsubscribed(hash)) return;

      this.showTextWillPrint();

      const fileBlob = await this.getFile(hash, "pdf", isResume);

      printJS(URL.createObjectURL(fileBlob));
    },

    edit(editingHash, isResume = true) {
      if (isResume) {
        this.$router.push({
          name: TEMPLATE_EDITING_NAMES.RESUME_EDITING,
          query: { hash: editingHash },
          params: {
            duplicate: true,
          },
        });

        return;
      }

      this.$router.push({
        name: TEMPLATE_EDITING_NAMES.COVER_LETTER_EDITING,
        query: { hash: editingHash },
        params: {
          duplicate: true,
        },
      });
    },

    async duplicate(hash, isResume = true) {
      await this.$store.dispatch("loader/show");

      try {
        if (isResume) {
          await this.$store.dispatch("resumeCreation/duplicateResume", {
            hash,
          });
          await this.$store.dispatch("userDashboard/getAllResumes");
        } else {
          await this.$store.dispatch("coverLetterCreation/duplicateLetter", {
            hash,
          });
          await this.$store.dispatch("userDashboard/getAllCoverLetters");
        }
      } catch (e) {
        console.error(e);
      }

      await this.$store.dispatch("loader/done");
    },

    async openModal(hash, isResume = true) {
      if (!this.redirectUnsubscribed(hash)) return;

      if (isResume) {
        this.currentResumeHash = hash;
      } else {
        this.currentLetterHash = hash;
      }

      this.isOpenModal = true;
    },

    closeModal() {
      this.currentResumeHash = this.currentLetterHash = null;
      this.isOpenModal = false;
    },

    async remove(hash, isResume = true) {
      await this.$store.dispatch("loader/pending");

      try {
        if (isResume) {
          await this.$store.dispatch("userDashboard/removeResume", hash);
          await this.$store.dispatch("userDashboard/getAllResumes");
          AppToast.toastSuccess("Resume was successfully deleted!");
        } else {
          await this.$store.dispatch("userDashboard/removeCoverLetter", hash);
          await this.$store.dispatch("userDashboard/getAllCoverLetters");
          AppToast.toastSuccess("Cover letter was successfully deleted!");
        }
      } catch (e) {
        console.error(e);
      }

      await this.$store.dispatch("loader/done");
    },

    async sendViaEmail(emailData) {
      await this.$store.dispatch("loader/pending");
      try {
        const formData = new FormData();

        formData.append(
          "hash",
          this.currentResumeHash || this.currentLetterHash
        );
        formData.append("from", emailData.from);
        formData.append("to", emailData.to);
        formData.append("subject", emailData.subject);
        if (emailData.cc_emails) {
          emailData.cc_emails.forEach((email, idx) =>
            formData.append(`cc_emails[${idx}]`, email)
          );
        }
        if (emailData.attachments) {
          emailData.attachments.forEach((file, idx) =>
            formData.append(`attachments[${idx}]`, file)
          );
        }
        formData.append("text", emailData.text);

        this.currentResumeHash
          ? await this.$store.dispatch(
              "userDashboard/sendResumeByEmail",
              formData
            )
          : await this.$store.dispatch(
              "userDashboard/sendLetterByEmail",
              formData
            );
      } catch (err) {
        console.error(err);
      }
      this.closeModal();
      await this.$store.dispatch("loader/done");
    },
  },

  async created() {
    await this.$store.dispatch("loader/pending");

    try {
      await this.$store.dispatch("userDashboard/getAllResumes");
      await this.$store.dispatch("userDashboard/getAllCoverLetters");
    } catch (e) {
      console.error(e);
    }

    this.mixpanelTrack("V2-USERDASHBOARD");

    FullStory.setUserVars({
      email: this.user.email,
    });

    await this.$store.dispatch("loader/done");
  },
};
</script>

<style lang="scss" scoped>
section.dashboard-section {
  padding-top: 40px;
  padding-bottom: 50px;
}

.dashboard-header {
  margin-bottom: 40px;

  &__text {
    font-size: 14px;
    line-height: 150%;
    color: $light-gray;
    margin-top: 8px;
  }
}

.user-templates {
  margin-bottom: 48px;

  &__title {
    margin-bottom: 24px;
  }
}

.templates {
  &__item {
    margin-bottom: 30px;
  }
}

.new-template {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  border: 2px dashed #8c8f96;
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  padding: 60px 20px;

  svg {
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
  }

  &:hover {
    border-color: $primary;
    color: $primary;

    svg {
      fill: $primary;
    }
  }
}

::v-deep .modal {
  padding: 48px;

  @media (max-width: map_get($grid-breakpoints, sm)) {
    padding: 20px;
    max-height: 678px;
  }

  @media (max-height: 678px) {
    max-height: 90vh;
  }

  &__close {
    @media (max-width: map_get($grid-breakpoints, xl)) {
      top: 30px;
      right: 30px;
    }
  }
}
</style>
