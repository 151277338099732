<template>
  <div class="download" v-click-outside="closeDD">
    <button
      type="button"
      class="download-btn"
      :class="{ 'download-btn--active': isOpenDD }"
      @click.prevent="toggleDD"
    >
      <AppIcon componentName="CloudIcon" class="download-btn__icon" />
      <p class="download-btn__title">Download</p>
      <AppIcon
        componentName="ChevronIcon"
        class="download-btn__icon download-btn__icon--arrow"
      />
    </button>

    <div class="download-dd" :class="{ 'download-dd--opened': isOpenDD }">
      <button
        type="button"
        class="download-dd-btn"
        @click.prevent="download('pdf')"
      >
        <AppIcon componentName="FilePdfIcon" class="download-dd-btn__icon" />
        <p class="download-dd-btn__label">.pdf</p>
      </button>

      <button
        type="button"
        class="download-dd-btn"
        @click.prevent="download('docx')"
      >
        <AppIcon componentName="FileDocIcon" class="download-dd-btn__icon" />
        <p class="download-dd-btn__label">.doc</p>
      </button>

      <button
        type="button"
        class="download-dd-btn"
        @click.prevent="download('txt')"
      >
        <AppIcon componentName="FileTxtIcon" class="download-dd-btn__icon" />
        <p class="download-dd-btn__label">.txt</p>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadOptions",

  data() {
    return {
      isOpenDD: false,
    };
  },

  methods: {
    toggleDD() {
      this.isOpenDD = !this.isOpenDD;
    },

    closeDD() {
      this.isOpenDD = false;
    },

    download(format) {
      this.$emit("download", format);
      this.closeDD();
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  &-btn {
    display: flex;
    align-items: center;
    width: 100%;
    color: #fff;
    padding: 8px 0;

    &--active {
      .download-btn__icon--arrow {
        transform: rotateX(180deg);
      }
    }

    &__icon {
      width: 20px;
      height: 20px;
      fill: #fff;
      transition: fill 0.3s ease;

      &--arrow {
        width: 11px;
        height: 7px;
        transition: transform 0.3s ease;
      }
    }

    &__title {
      flex: 1;
      font-weight: 500;
      font-size: 14px;
      transition: color 0.3s ease;
      padding: 0 8px;
      text-align: left;
    }
  }
}

.download-dd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  transition: all 0.3s ease;
  max-height: 0;
  overflow: hidden;

  &--opened {
    max-height: 70px;
    padding: 8px 0;
  }

  &-btn {
    padding: 4px 8px;
    border-radius: 8px;

    &__icon {
      width: 24px;
      height: 24px;
      fill: #fff;
    }

    &__label {
      font-weight: 500;
      font-size: 11px;
      line-height: 150%;
      color: #fff;
    }

    &:hover {
      background-color: #527eef;
    }
  }
}
</style>
