<template>
  <div class="no-templates">
    <div class="no-templates-inner">
      <img
        src="@/assets/images/no-template-img.svg"
        alt=""
        class="no-templates-inner__img"
      />

      <h3 class="no-templates-inner__title">{{ title }}</h3>

      <p class="no-templates-inner__text">
        <slot />
      </p>

      <AppButton
        v-if="isResume"
        title="Browse Templates"
        class="no-templates-inner__btn"
        className="primary"
        iconName="SearchIcon"
        tag="router-link"
        :to="ROUT_PATH.BROWS_RESUME_TEMPLATES"
      />

      <AppButton
        v-if="!isResume"
        title="Browse Templates"
        class="no-templates-inner__btn"
        className="primary"
        iconName="SearchIcon"
        tag="router-link"
        :to="ROUT_PATH.BROWS_LETTERS_TEMPLATES"
      />
    </div>
  </div>
</template>

<script>
import { ROUT_PATH } from "@/helpers/rout-constants";

export default {
  name: "NoTemplates",
  props: {
    title: {
      type: String,
    },

    isResume: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      ROUT_PATH,
    };
  },

  created() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.no-templates {
  padding: 44px 30px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;

  &-inner {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 600px;

    &__img {
      width: 100px;
      height: 100px;
      margin-bottom: 24px;
    }

    &__title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 8px;
    }

    &__text {
      font-size: 12px;
      line-height: 150%;
      color: $light-gray;
      margin-bottom: 24px;
    }
  }
}
</style>
